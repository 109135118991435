<template>
	<div class="comment-box">
		<wx-comment-item
				class="comment-item"
				v-for="(i, index) in myComment"
				:key="index"
				:name="name"
				:comment="i"/>
	</div>


</template>

<script>
import WxCommentItem from './Item'

export default {
	// 微信评论
	name: 'WxComment',
	components: {
		WxCommentItem: WxCommentItem
	},
	props: {
		comment: {
			type: [Array, String],
			default: () => []
		},
		name: {
			type: String,
			default: ''
		}
	},
	computed: {
		myComment(){
			return Array.isArray(this.comment) ? this.comment : [this.comment]
		}
	}
}
</script>

<style lang="scss" scoped>
.comment-box {
	border-radius: 4px;
	margin: 0;
	.comment-item {
		margin-top: 5px;
	}
}
</style>
