<template>
	<div class="comment" >
		<span class="comment-name">{{ name }}:</span>
		{{ comment }}
	</div>
</template>

<script>
export default {
	// 微信评论
	name: 'WxCommentItem',
	props: {
		comment: {
			type: String,
			default: ''
		},
		name: {
			type: String,
			default: ''
		}
	},
}
</script>

<style lang="scss" scoped>
.comment {
	padding: 10px;
	background: #F8FAFA;
	white-space: pre-wrap;
	word-wrap: break-word;
	word-break: break-all;
	overflow: hidden;
	font-size: 12px;
	font-weight: 500;
	color: #181818;
	line-height: 20px;
	margin-bottom: 5px;
	border-radius: 4px;

	&:last-child {
		margin-top: 0;
	}

	.comment-name {
		font-size: 14px;
		color: #35557C;
		line-height: 20px;
	}
}

</style>
